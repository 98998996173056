<template>
<div class=''>
    <router-view />
</div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {

  },
  // 方法集合
  methods: {

  },
};
</script>
<style lang='scss' >
</style>
